/* Gallery.css */
.title {
  align-items: center;
  align-content: center;
  font-size: large;
}
.add-btn {
  background: #1777fe;
  margin: 0 5px 10px 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background 0.3s ease, border-color 0.3s ease;
  width: 150px;
  height: 40px;
  font-size: medium;
  color: white;
  border: solid;
  border-color: #1777fe;
  border-radius: 7px;
  align-items: center;
  justify-content: center;
  /* Add any additional styles for the delete button here */
}
.gallery-item {
  position: relative;
  margin-bottom: 16px;
  width: 200px;
  height: 200px;
  border: 2px solid #1777fe;
}

.image-container {
  position: relative;
  width: 200px;
  height: 200px;
}

.gallery-image {
  width: 100%;
  height: auto;
  display: block;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  transition: transform 0.3s ease;

  /* Add any additional styles you want for the image here */
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

.delete-button {
  background: white;
  margin-left: 28%;
  margin-top: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background 0.3s ease, border-color 0.3s ease;
  width: 80px;
  height: 30px;
  font-size: medium;
  color: hsl(359, 68%, 67%);
  border: solid;
  border-color: hsl(359, 68%, 67%);
  border-radius: 7px;
  align-items: center;
  justify-content: center;
  /* Add any additional styles for the delete button here */
}

.delete-button:hover {
  background: hsl(359, 68%, 67%);
  border-color: hsl(359, 68%, 67%);
  color: white;
}

.gallery-item:hover .image-overlay {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.gallery-item:hover .gallery-image {
  transform: scale(1.05);
}

.gallery-image-container {
  height: 100%;
  width: 100%;
  display: block;
  padding: 10px;
}

.gallery-image-container .ant-image {
  height: 100%;
  width: 100%;
}
