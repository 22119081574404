.moved-table-row {
  background-color: #f0fde9;
}

.pending-table-row {
  background-color: #f0fde9;
}

.canceled-table-row {
  background-color: #fff1f0;
}

.processing-table-row {
  background-color: #ffd580;
}

.garson-failed-row {
  font-weight: bold;
}
